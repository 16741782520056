import axios from "axios";
import swal from "sweetalert";
import {
  errorMessage,
  tokenExpired,
  errorNotFoundMessage
} from "@/api/errorMessages";

export default {
  namespaced: true,
  state: {
    tags: null
  },
  getters: {
    showTags(state) {
      return state.tags;
    }
  },
  mutations: {
    setTags(state, newTags) {
      state.tags = newTags;
    }
  },
  actions: {
    async getTags({ commit }) {
      try {
        let response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `api/db/tags`
        );
        if (response.status == 200) {
          commit("setTags", response.data);
        }
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status != 200) {
          errorMessage();
        }
        return err.response;
      }
    },
    async createNewTag(_, params) {
      try {
        let response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE + `api/db/tags`,
          params
        );
        if (response.status == 201) {
          swal({
            title: "Tag successfully created",
            icon: "success"
          });
        }
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status != 201) {
          errorMessage();
        }
        return err.response;
      }
    },
    async deleteTag(_, tag_id) {
      try {
        let response = await axios.delete(
          process.env.VUE_APP_BACK_ROUTE + `api/db/tags/${tag_id}`
        );
        if (response.status == 200) {
          swal({
            title: "Tag successfully deleted",
            icon: "success"
          });
        }
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 404) {
          errorNotFoundMessage("Tag");
        } else if (err.response.status != 200) {
          errorMessage();
        }
        return err.response;
      }
    },
    async updateTag(_, data) {
      try {
        let response = await axios.patch(
          process.env.VUE_APP_BACK_ROUTE + `api/db/tags/${data.id}`,
          data.body
        );
        if (response.status == 204) {
          swal({
            title: "Tag successfully updated",
            icon: "success"
          });
        }
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 404) {
          errorNotFoundMessage("Tag");
        } else if (err.response.status != 204) {
          errorMessage();
        }
        return err.response;
      }
    }
  }
};
