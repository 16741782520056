import axios from "axios";
import { errorMessage, tokenExpired } from "@/api/errorMessages";

export default {
    namespaced: true,
    state: {
        payeeName: null,
    },
    mutations: {
        setListByName(state, name) {
            state.payeeName = name;
        },
    },
    actions: {
        async getListByName({ commit }, name) {
            try {
                let response = await axios.get(
                    process.env.VUE_APP_BACK_ROUTE + `api/crm/accounts/list/${name}`
                );
                if (response.status == 200) {
                    commit("setListByName", response.data);
                }
                return response;
            } catch (err) {
                if (err.response.status == 401) {
                    tokenExpired();
                } else if (err.response.status != 200) {
                    errorMessage();
                }
            }
        }
    }
}