import axios from "axios";
import {
  errorMessage,
  tokenExpired,
  errorNotFoundMessage
} from "@/api/errorMessages";

export default {
  namespaced: true,
  state: {
    fileFromBucket: null,
    htmlBodyEmail: null,
    images: [],
    filesList: [],
    thereAreFilesFlag: 0
  },
  getters: {
    showImages(state) {
      return state.images;
    }
  },
  mutations: {
    setFilesFromBucket(state, file) {
      state.fileFromBucket = file;
    },
    setHtmlBody(state, html) {
      state.htmlBodyEmail = html;
    },
    setImages(state, file) {
      state.images.push(file);
    },
    cleanImages(state) {
      state.images = [];
    },
    setFilesList(state, pdf) {
      state.filesList.push(pdf);
    },
    setThereAreFilesFlag(state) {
      state.thereAreFilesFlag++;
    }
  },
  actions: {
    async getFilesFromBucket({ commit }, data) {
      try {
        let file;
        let response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `api/bucket/files`,
          {
            params: { path: data.path },
            responseType: "blob",
            transformResponse: [
              function(data, headers) {
                let typeBlob;
                typeBlob = headers["content-type"];
                let blob = new window.Blob([data], {
                  type: typeBlob
                });
                file = new File([blob], headers.filename, {
                  type: blob.type
                });
                return file;
              }
            ]
          }
        );
        file.loadId = data.loadId;
        if (response.status == 200) {
          commit("setFilesFromBucket", file);
          if (file.type.search("image") !== -1) {
            commit("setImages", file);
          }
          commit("setFilesList", file);
          return file;
        }
        return file;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 404) {
          errorNotFoundMessage("File");
        } else if (err.response.status != 200) {
          errorMessage();
        }
      }
    },
    async getHtmlbody({ commit }, params) {
      try {
        let response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `api/bucket/files`,
          {
            params: params
          }
        );
        if (response.status == 200) {
          commit("setHtmlBody", response.data);
        }
        return response.data;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 404) {
          errorNotFoundMessage("File");
        } else if (err.response.status != 200) {
          errorMessage();
        }
      }
    },
    async saveFilesBucket(_, body) {
      let params = {};
      params.path = body.path;
      let bodyFormData = new FormData();
      bodyFormData.append("file", body.file);
      try {
        let response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE + `api/bucket/files`,
          bodyFormData,
          {
            params: params
          }
        );
        return response.data;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status != 204) {
          errorMessage();
        }
      }
    }
  }
};
