import axios from "axios";
import { errorMessage, tokenExpired, tokenExpiredSalesForce } from "@/api/errorMessages";

export default {
  namespaced: true,
  state: {
    dispatcherInfo: null,
    shpCodesObject: null
  },
  mutations: {
    setDispatcherInfo(state, newDispatcherInfo) {
      state.dispatcherInfo = newDispatcherInfo;
    },
    setGetShpCode(state, newObject) {
      state.shpCodesObject = newObject;
    }
  },
  getters: {
    showShpCodesObject(state) {
      return state.shpCodesObject;
    }
  },
  actions: {
    async getDispatcherInfo({ commit }, salesforceId) {
      try {
        let response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `api/crm/loads/${salesforceId}`
        );
        if (response.status == 200) {
          commit("setDispatcherInfo", response.data);
        }
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 403) {
          tokenExpiredSalesForce();
        } else if (err.response.status != 200) {
          errorMessage();
        }
      }
    },
    async getShpCode({ commit }, SHP) {
      try {
        let response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `api/crm/loads/list/${SHP}`
        );
        if (response.status == 200) {
          commit("setGetShpCode", response.data);
        }
        return response.data;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 403) {
          tokenExpiredSalesForce();
        } else if (err.response.status != 200) {
          errorMessage();
        }
      }
    }
  }
};
