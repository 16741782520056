import axios from "axios";
import swal from "sweetalert";
import router from "@/router";
import { errorMessage } from "@/api/errorMessages";

export default {
  namespaced: true,
  state: {
    token: null,
    userInfo: null
  },
  getters: {
    showUserInfo(state) {
      return state.userInfo;
    }
  },
  mutations: {
    setToken(state, newToken) {
      state.token = newToken;
    },
    setUserInfo(state) {
      let token = localStorage.getItem("payments2.0-token");

      if (token) {
        let base64Url = token.split(".")[1];
        let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        let jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function(character) {
              return (
                "%" + ("00" + character.charCodeAt(0).toString(16)).slice(-2)
              );
            })
            .join("")
        );
        state.userInfo = JSON.parse(jsonPayload);
        let dateNow = new Date();
        let expirationDate = new Date(state.userInfo.exp * 1000);
        if (dateNow.getTime() > expirationDate.getTime()) {
          localStorage.removeItem("payments2.0-token");
          router.push({
            name: "Login"
          });
        }
      } else {
        router.push({
          name: "Login"
        });
      }
    }
  },
  actions: {
    async login({ commit }, body) {
      let bodyFormData = new FormData();
      bodyFormData.append("username", body.username);
      bodyFormData.append("password", body.password);
      try {
        let response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE + `api/auth-crm/access-token`,
          bodyFormData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            }
          }
        );
        if (response.status == 200) {
          localStorage.setItem("payments2.0-token", response.data.access_token);
          commit("setToken", response.data);
        }
        commit("setUserInfo");
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          swal({
            title: "There is something wrong",
            text: "User or Password incorrect",
            icon: "error",
            dangerMode: true
          });
        } else if (err.response.status != 200) {
          errorMessage();
        }
        return err.response;
      }
    }
  }
};
