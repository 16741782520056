import axios from "axios";
import { errorMessage, tokenExpired } from "@/api/errorMessages";

export default {
    namespaced: true,
    state: {
        payablesInfo: null
    },
    mutations: {
        setPayables(state, newPayables) {
            state.payablesInfo = newPayables;
        }
    },
    getters: {
        showSetPayables(state) {
            return state.payablesInfo.id;
        }
    },
    actions: {
        /* Function to get DB Payables by load_id */
        async getPayables({ commit }, loadId) {
            try {
                let response = await axios.get(
                    process.env.VUE_APP_BACK_ROUTE + `api/db/payables`,
                    {
                        params: loadId
                    }
                );
                if (response.status == 200) {
                    commit("setPayables", response.data);
                }
                return response.data;
            } catch (err) {
                if (err.response.status == 401) {
                    tokenExpired();
                } else if (err.response.status != 200) {
                    errorMessage();
                }
                return err.response;
            }
        },
    }
}