import axios from "axios";
import { errorMessage, tokenExpired } from "@/api/errorMessages";

export default {
  namespaced: true,
  state: {
    loadsCreate: null,
    loadsInfo: [],
    loadById: [],
    loads: null
  },
  mutations: {
    setCreateLoads(state, createLoads) {
      state.loadsCreate = createLoads;
    },
    setLoadsInfo(state, loadInfo) {
      state.loadsInfo.push(loadInfo);
    },
    setCleanLoadsInfo(state) {
      state.loadInfo = [];
    },
    setGetLoads(state, getLoads) {
      state.loads = getLoads;
    },
    setLoadById(state, infoLoadById) {
      state.loadById.push(infoLoadById);
    },
    setCleanLoadsById(state) {
      state.loadsById = [];
    }
  },
  getters: {
    showLoadIdInfo(state) {
      return state.loadsInfo;
    },
    showInfoLoadById(state) {
      return state.loadById;
    }
  },
  actions: {
    async createLoads({ commit }, body) {
      try {
        let response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE + `api/db/loads`,
          body
        );
        if (response.status == 201) {
          commit("setCreateLoads", response.data);
        }
        return response.data;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status != 201) {
          errorMessage();
        }
      }
    },
    async loadsById({ commit }, loadId) {
      try {
        let response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `api/db/loads/${loadId}`
        );
        if (response.status == 200) {
          commit("setLoadById", response.data);
          return response.data;
        }
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status != 200) {
          errorMessage();
        }
      }
    },
    /* Function to get DB Loads by email_id */
    async getLoads({ commit }, emailId) {
      try {
        let response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `api/db/loads`,
          {
            params: emailId
          }
        );
        if (response.status == 200) {
          commit("setGetLoads", response.data);
        }
        return response.data;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status != 200) {
          errorMessage();
        }
      }
    }
  }
};
