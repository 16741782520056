import axios from "axios";
import swal from "sweetalert";
import {
  errorMessage,
  tokenExpired,
  errorNotFoundMessage
} from "@/api/errorMessages";

export default {
  namespaced: true,
  state: {
    users: null,
    roles: null,
    user: null,
    rolByUserId: null
  },
  getters: {
    showRolByUserId(state) {
      return state.rolByUserId;
    }
  },
  mutations: {
    setUsers(state, newUsers) {
      state.users = newUsers;
    },
    setGetRoles(state, newRoles) {
      state.roles = newRoles;
    },
    setUser(state, newUser) {
      state.user = newUser;
    },
    setGetRolByUserId(state, newRolByUserId) {
      state.rolByUserId = newRolByUserId;
    }
  },
  actions: {
    /* Function to get the user by Id */
    async getUserById(_, userId) {
      try {
        let response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `api/addons/users/${userId}`
        );
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 404) {
          errorNotFoundMessage("Users");
        } else if (err.response.status != 200) {
          errorMessage();
        }
        return err.response;
      }
    },
    /* Function to get the user */
    async getUsers({ commit }) {
      let params = {};
      params.limit = 1000;
      try {
        let response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `api/addons/users`,
          {
            params: params
          }
        );
        if (response.status == 200) {
          commit("setUsers", response.data);
        }
        return response.data;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status != 200) {
          errorMessage();
        }
        return err.response;
      }
    },
    /* Function to get the role */
    async getRol({ commit }) {
      try {
        let response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `api/addons/roles`
        );
        if (response.status == 200) {
          commit("setGetRoles", response.data);
        }
        return response.data;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status != 200) {
          errorMessage();
        }
        return err.response;
      }
    },
    /* Function to get the role of the user by id */

    async getRolByUserId({ commit }) {
      try {
        let response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `api/addons/users/rol`
        );
        if (response.status == 200) {
          commit("setGetRolByUserId", response.data);
        }
        return response.data;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 404) {
          errorNotFoundMessage("Rol");
        } else if (err.response.status != 200) {
          errorMessage();
        }
        return err.response;
      }
    },
    /* Function to create a new user */
    async createNewUser(_, params) {
      try {
        let response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE + `api/addons/users`,
          params
        );
        if (response.status == 201) {
          swal({
            title: "User successfully created",
            icon: "success"
          });
        }
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status != 201) {
          errorMessage();
        }
        return err.response;
      }
    },
    /* Function to update a user */
    async updateUser(_, data) {
      try {
        let response = await axios.patch(
          process.env.VUE_APP_BACK_ROUTE + `api/addons/users/${data.id}`,
          data.body
        );
        if (response.status == 204) {
          swal({
            title: "User successfully updated",
            icon: "success"
          });
        }
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 404) {
          errorNotFoundMessage("User");
        } else if (err.response.status != 204) {
          errorMessage();
        }
        return err.response;
      }
    },
    /*  Function to restore the status of a user on the platform in case an admin type user decides to do so. */
    async restoreUser(_, params) {
      try {
        let response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE + `api/addons/users/${params.id}`,
          params
        );
        if (response.status == 204) {
          swal({
            title: "User status is now ACTIVE",
            icon: "success"
          });
        }
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 404) {
          errorNotFoundMessage("User");
        } else if (err.response.status != 204) {
          errorMessage();
        }
        return err.response;
      }
    },
    /* Function to disable a user in case an admin type user decides so */
    async deleteUser(_, params) {
      try {
        let response = await axios.delete(
          process.env.VUE_APP_BACK_ROUTE + `api/addons/users/${params.id}`,
          params
        );
        if (response.status == 204) {
          swal({
            title: "User status is now INACTIVE",
            icon: "success"
          });
        }
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 404) {
          errorNotFoundMessage("User");
        } else if (err.response.status != 204) {
          errorMessage();
        }
        return err.response;
      }
    },
    /* Function to change the status of the logged in user in case of busy, end of day or other unavailable situation.  */
    async changeStatusUser(_, data) {
      try {
        let response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE + `api/addons/users/change/status`,
          null,
          { params: data }
        );
        if (response.status == 204) {
          swal({
            title: "User successfully change status",
            icon: "success"
          });
        }
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 404) {
          errorNotFoundMessage("User");
        } else if (err.response.status != 204) {
          errorMessage();
        }
        return err.response;
      }
    }
  }
};
