import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

// importing the helper
import interceptorsSetup from "@/api/interceptors";

//Froala Editor
import "@/plugins/froalaEditor";

//BootstrapVue
import "@/plugins/bootstrapVue";
//fontAwesome
import "@/plugins/fontAwesome";
//sweetAlert
import "@/plugins/sweetAlert";
//primeVue
import "@/plugins/primeVue";

//filters
import "@/filters/dayjs";

Vue.prototype.$http = axios;
Vue.config.productionTip = false;

import PrimeVue from 'primevue/config';
Vue.use(PrimeVue);

interceptorsSetup();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

if ("serviceWorker" in navigator) {
  window.addEventListener("load", function() {
    navigator.serviceWorker.register(`/service-worker.js`);
  });
}
