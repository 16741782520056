import axios from "axios";
import swal from "sweetalert";
import {
  errorMessage,
  tokenExpired,
  errorNotFoundMessage
} from "@/api/errorMessages";

export default {
  namespaced: true,
  state: {
    emails: null,
    id: 0,
    showEmails: false,
    deleteFlag: 0,
    emailsRestore: 0,
    count: 0,
    emailsById: null,
    getEmailContent: null,
    createEmail: null,
    emailsImages: null
  },
  mutations: {
    setEmails(state, newEmails) {
      state.emails = newEmails;
    },
    setShowEmails(state, newState) {
      state.showEmails = newState;
      state.id += 1;
    },
    setEmailsCount(state, newEmailsCount) {
      state.count = newEmailsCount;
    },
    setEmailsById(state, emailById) {
      state.emailsById = emailById;
    },
    setEmailsContent(state, emailContent) {
      state.getEmailContent = emailContent;
    },
    setCreateEmail(state, infoCreateEmail) {
      state.createEmail = infoCreateEmail;
    },
    setEmailsImages(state, images) {
      state.emailsImages = images;
    }
  },
  getters: {
    showEmailById(state) {
      return state.emailsById;
    },
    showEmailContent(state) {
      return state.getEmailContent;
    },
    showInfoEmail(state) {
      return state.createEmail;
    }
  },
  actions: {
    async getEmailsInQueue({ commit }, params) {
      try {
        let response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `api/db/emails`,
          {
            params: params
          }
        );
        if (response.status == 200) {
          commit("setEmails", response.data);
        }
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status != 200) {
          errorMessage();
        }
      }
    },
    async getEmailsById({ commit }, msgId) {
      try {
        let response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `api/db/emails/${msgId}`
        );
        if (response.status == 200) {
          commit("setEmailsById", response.data);
        }
        return response.data;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 404) {
          errorNotFoundMessage("Email");
        } else if (err.response.status != 200) {
          errorMessage();
        }
      }
    },
    async getEmailsContent({ commit }, params) {
      try {
        let response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `api/db/emails/content`,
          {
            params: params
          }
        );
        if (response.status == 200) {
          commit("setEmailsContent", response.data);
        }
        return response.data;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 404) {
          errorNotFoundMessage("Email content");
        } else if (err.response.status != 200) {
          errorMessage();
        }
      }
    },
    async getEmailsImages({ commit }, uidMsg) {
      let params = {};
      params.uid_msg = uidMsg;
      try {
        let response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `api/db/emails/images`,
          {
            params: params
          }
        );
        if (response.status == 200) {
          commit("setEmailsImages", response.data);
        }
        return response.data;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 404) {
          errorNotFoundMessage("Email Images");
        } else if (err.response.status != 200) {
          errorMessage();
        }
      }
    },
    async deleteEmails(_, emailId) {
      try {
        let response = await axios.delete(
          process.env.VUE_APP_BACK_ROUTE + `api/db/emails/${emailId}`
        );
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 404) {
          errorNotFoundMessage("Email");
        } else if (err.response.status != 204) {
          errorMessage();
        }
      }
    },
    async sendToEmails(_, data) {
      try {
        let response = await axios.patch(
          process.env.VUE_APP_BACK_ROUTE + `api/db/emails/${data.id}`,
          data.body
        );
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 404) {
          errorNotFoundMessage("Email");
        } else if (err.response.status != 204) {
          errorMessage();
        }
      }
    },
    async restoreEmails(_, emailId) {
      try {
        let response = await axios.patch(
          process.env.VUE_APP_BACK_ROUTE + `api/db/emails/restore/${emailId}`
        );
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 404) {
          errorNotFoundMessage("Email");
        } else if (err.response.status != 204) {
          errorMessage();
        }
      }
    },
    async getCount({ commit }, params) {
      try {
        let response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `api/db/emails/count`,
          {
            params: params
          }
        );
        if (response.status == 200) {
          commit("setEmailsCount", response.data);
        }
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status != 204) {
          errorMessage();
        }
      }
    },

    async createEmail({ commit }) {
      try {
        let response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE + `api/db/emails`
        );
        if (response.status == 201) {
          commit("setCreateEmail", response.data);
        }
        return response.data;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status != 201) {
          errorMessage();
        }
      }
    },
    async sendEmail(_, data) {
      try {
        let response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE + `api/mailman/reply`,
          data.body,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        );
        if (response.status == 204) {
          swal("Good Job!", "The message was successfully sent.", "success");
        }
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 404) {
          errorNotFoundMessage("Mail");
        } else if (err.response.status != 200) {
          errorMessage();
        }
        return err.response;
      }
    }
  }
};
