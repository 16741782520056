import axios from "axios";
import { errorMessage, tokenExpired } from "@/api/errorMessages";

export default {
    namespaced: true,
    state: {
        history: null
    },
    mutations: {
        setHistory(state, newHistory) {
            state.history = newHistory;
        }
    },
    actions: {
        async getHistory({ commit }, params) {
            try {
                let response = await axios.get(
                    process.env.VUE_APP_BACK_ROUTE + `api/db/salesforce-history`,
                    {
                        params: params
                    }
                );
                if (response.status == 200) {
                    commit("setHistory", response.data);
                }
                return response.data;
            } catch (err) {
                if (err.response.status == 401) {
                    tokenExpired();
                } else if (err.response.status != 200) {
                    errorMessage();
                }
                return err.response;
            }
        }
    }
}