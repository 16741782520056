import axios from "axios";
import {
  errorMessage,
  tokenExpired,
  errorNotFoundMessage
} from "@/api/errorMessages";

export default {
  namespaced: true,
  state: {
    createEmailTag:null
  },
  getters: {},
  mutations: {
    setCreateEmailTag(state, emailId){
      state.createEmailTag = emailId;
    }
  },
  actions: {
    async createEmailXTag( _, body) {
      try {
        let response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE + `api/db/email-x-tag`,
          body
        );
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status != 201) {
          errorMessage();
        }
        return err.response;
      }
    },
    async removeEmailXTag(_, params) {
      try {
        let response = await axios.delete(
          process.env.VUE_APP_BACK_ROUTE + `api/db/email-x-tag/${params}`
        );
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 404) {
          errorNotFoundMessage("Email X Tag");
        } else if (err.response.status != 200) {
          errorMessage();
        }
        return err.response;
      }
    }
  }
};
