<template>
  <div id="app">
    <div v-if="$route.path != '/'">
      <LogoutButton />
    </div>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import LogoutButton from "@/components/LogoutButton";

export default {
  name: "App",
  components: {
    LogoutButton
  },
  data() {
    return {
      loggedUserId: null,
      userRol: null,
      counterNotification: 0,
      token: null
    };
  },
  async created() {
    this.$store.commit("login/setUserInfo");
    this.token = localStorage.getItem("payments2.0-token");
    if (this.token) {
      this.userRol = await this.$store.dispatch("users/getRolByUserId");
      this.loggedUserId = JSON.parse(
        this.$store.getters["login/showUserInfo"].sub
      ).id;
    }
  },
  mounted() {
    this.broadcastChannel = new BroadcastChannel("sw-messages");
    this.broadcastChannel.addEventListener("message", this.pusherEventHandler);
  },
  methods: {
    pusherEventHandler(channel) {
      if (this.token && this.$route.path != "/") {
        if (channel.data.info.type == "user" && this.userRol.rol_id == 3) {
          this.$bvToast.toast(channel.data.notification.body, {
            title: channel.data.notification.title,
            toaster: "b-toaster-bottom-right",
            variant: channel.data.info.variant,
            solid: true,
            autoHideDelay: 10000
          });
        }
        if (channel.data.info.type != "user") {
          this.$bvToast.toast(channel.data.notification.body, {
            title: channel.data.notification.title,
            toaster: "b-toaster-bottom-right",
            variant: channel.data.info.variant,
            solid: true,
            autoHideDelay: 10000
          });
        }
        if (
          channel.data.info.type == "email" &&
          this.$route.params.pathName &&
          this.$route.name != "InfoEmail"
        ) {
          this.counterNotification += 1;
          if (this.counterNotification % 3 == 0) {
            this.$store.commit("setNotification");
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#app {
  color: $color-background-dark;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.4s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
