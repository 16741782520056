import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js";

Vue.use(VueRouter);

const routerOptions = [
  {
    path: "/",
    name: "Login"
  },
  {
    path: "/payments",
    name: "Payments",
    children: [
      {
        path: ":pathName/:id?",
        name: "Emails",
        component: () => import(`@/views/Payments/children/emails/Index.vue`)
      },
      {
        path: ":pathName/form/:id",
        name: "InfoEmail",
        component: () => import(`@/views/Payments/children/infoEmail/Index.vue`)
      },
      {
        path: "settings",
        name: "Settings",
        component: () => import(`@/views/Payments/children/settings/Index.vue`),
        beforeEnter: async (to, from, next) => {
          let rolId = await store.dispatch("users/getRolByUserId");
          if (rolId.rol_id == 3) {
            next();
          } else {
            next("/PageNotAllowed");
          }
        },
        children: [
          {
            path: "users/:option?/:id?",
            name: "Users",
            component: () =>
              import(`@/views/Payments/children/settings/Index.vue`)
          },
          {
            path: "tags/:option?/:id?",
            name: "Tags",
            component: () =>
              import(`@/views/Payments/children/settings/Index.vue`)
          }
        ]
      },
      {
        path: "/PageNotAllowed",
        name: "PageNotAllowed",
        component: () => import(`@/views/PageNotAllowed/Index.vue`)
      },
      {
        path: "/404",
        name: "NotFound",
        component: () => import(`@/views/NotFound/Index.vue`)
      }
    ]
  },
  {
    path: "*" /** El * Siempre al final o regex*/,
    redirect: "/404"
  }
];

const routes = routerOptions.map(route => {
  return {
    ...route,
    component: () => import(`@/views/${route.name}/Index.vue`)
  };
});

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
