import axios from "axios";
import {
  errorMessage,
  tokenExpired,
  errorNotFoundMessage
} from "@/api/errorMessages";

export default {
  namespaced: true,
  state: {
    dbFiles: null,
    createFiles: [],
    fileSelected: null,
    filesSchemas: [],
    files: [],
    idTabTransfer: null,
    tabState: 1,
    loadsOptions: [1],
    emailSelected: null
  },
  mutations: {
    setDbFiles(state, file) {
      state.dbFiles = file;
    },
    setCleanDbFiles(state) {
      state.dbFiles = null;
    },
    setCreateFiles(state, files) {
      state.createFiles.push(files);
    },
    idFileSelected(state, idSelected) {
      state.fileSelected = idSelected;
    },
    setFilesSchemas(state, newFileSchema) {
      state.filesSchemas = newFileSchema;
    },
    setHandleSelected(state, newFiles) {
      state.files.push(newFiles);
      let length = state.files.length;
      if (state.files.length > 1) {
        for (let i = 0; i < length; i++) {
          for (let j = i + 1; j < length; j++) {
            if (state.files[i].name == state.files[j].name) {
              state.files.splice(i, 1);
              length = state.files.length;
            }
          }
        }
      }
    },
    deleteFiles(state, fileDeleted) {
      for (let i in state.files) {
        if (fileDeleted.name == state.files[i].name) {
          state.files.splice(i, 1);
        }
      }
    },
    setTabState(state, newTabState) {
      state.tabState = newTabState;
    },
    setLoadsOptions(state, loadsOptionsList) {
      state.loadsOptions = loadsOptionsList;
    },
    setDeletedTabFiles(state, tab) {
      state.files = state.files.filter(file => file.tab !== tab);
    },
    setEmailSelected(state, emailSelected) {
      state.emailSelected = emailSelected;
    },
    setCleanFiles(state) {
      state.files = [];
    },
    setCleanCreatedFiles(state) {
      state.showFilesCreated = [];
    },
    setCleanLoadOptions(state) {
      state.loadsOptions = [1];
    },
    cleanFilesArrays(state) {
      state.createFiles = [];
      state.filesSchemas = [];
      state.files = [];
    }
  },
  getters: {
    showLoadOptions(state) {
      return state.loadsOptions;
    },
    showLoadsFileState(state) {
      return state.loadsFilesState;
    },
    showDbFile(state) {
      return state.dbFiles;
    },
    showIdFileSelected(state) {
      return state.fileSelected;
    },
    showFilesTransfer(state) {
      return state.files;
    },
    showTabSelected(state) {
      return state.tabState;
    },
    showLoadsOptions(state) {
      return state.loadsOptions;
    },
    showFilesCreated(state) {
      return state.createFiles;
    },
    showInfoEmailSelected(state) {
      return state.emailSelected;
    }
  },
  actions: {
    async getDbFiles({ commit }, params) {
      try {
        let response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `api/db/files`,
          {
            params: params
          }
        );
        if (response.status == 200) {
          commit("setDbFiles", response.data);
        }
        return response.data;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status != 200) {
          errorMessage();
        }
      }
    },
    async createFiles({ commit }, body) {
      try {
        let response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE + `api/db/files`,
          body
        );
        if (response.status == 201) {
          commit("setCreateFiles", response.data);
        }
        return response.data;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status != 201) {
          errorMessage();
        }
      }
    },
    async deleteFiles(_, fileId) {
      try {
        let response = await axios.delete(
          process.env.VUE_APP_BACK_ROUTE + `api/db/files/${fileId}`
        );
        return response.status;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 404) {
          errorNotFoundMessage("File");
        } else if (err.response.status != 200) {
          errorMessage();
        }
      }
    },
    async manualScheme(_, body) {
      let scheme = {};
      if (body.load_id != null) {
        scheme.load_id = body.load_id;
      }
      if (body.pages) {
        scheme.pages = body.pages.filter(el => el.type !== "select");
      }
      try {
        let response = await axios.patch(
          process.env.VUE_APP_BACK_ROUTE +
            `api/db/files/${body.fileIdSelected}`,
          scheme
        );
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 404) {
          errorNotFoundMessage("File");
        } else if (err.response.status != 204) {
          errorMessage();
        }
      }
    },
    async getEmailInformationByEmailId(_, emailId) {
      try {
        let response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `api/db/emails/${emailId}`
        );
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 404) {
          errorNotFoundMessage("Email");
        } else if (err.response.status != 200) {
          errorMessage();
        }
        return err.response;
      }
    },
    async getEmailFileHtml(_, params) {
      try {
        let response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `api/bucket/files`,
          {
            params: params
          }
        );
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 404) {
          errorNotFoundMessage("File");
        } else if (err.response.status != 200) {
          errorMessage();
        }
        return err.response;
      }
    }
  }
};
