import axios from "axios";
import {
  errorMessage,
  tokenExpired,
  tokenExpiredSalesForce
} from "@/api/errorMessages";

export default {
  namespaced: true,
  state: {
    payablesLine: null
  },
  mutations: {
    setPayablesLine(state, payload) {
      state.payablesLine = payload;
    }
  },
  actions: {
    async getPayableLines({ commit }, payablesId) {
      try {
        let response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `api/crm/payable-lines/${payablesId}`
        );
        if (response.status == 200) {
          commit("setPayablesLine", response.data);
        }
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 403) {
          tokenExpiredSalesForce();
        } else if (err.response.status != 200) {
          errorMessage();
        }
      }
    },
    async updatePayablesLine(_, data) {
      let params = {};
      params.payable_db_id = data.payable_db_id;
      try {
        let response = await axios.patch(
          process.env.VUE_APP_BACK_ROUTE +
            `api/crm/payable-lines/${data.payable_line_id}`,
          data.body,
          {
            params: params
          }
        );
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 403) {
          tokenExpiredSalesForce();
        } else if (err.response.status != 204) {
          errorMessage();
        }
        return err.response;
      }
    }
  }
};
