import axios from "axios";
import { errorMessage, tokenExpired } from "@/api/errorMessages";

export default {
  namespaced: true,
  state: {
    pageTypes: null
  },
  mutations: {
    setPageTypes(state, newPageTypes) {
      state.pageTypes = newPageTypes;
    }
  },
  actions: {
    async getPageTypes({ commit }) {
      try {
        let response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `api/db/page-types`
        );
        if (response.status == 200) {
          commit("setPageTypes", response.data);
        }
        return response.data;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status != 200) {
          errorMessage();
        }
      }
    }
  }
};
