import * as PusherPushNotifications from "@pusher/push-notifications-web";

class BeamClient {
  userId = null;
  beamsClient = null;
  beamsTokenProvider = null;

  constructor(userId) {
    let token = localStorage.getItem("payments2.0-token");
    this.userId = userId;
    this.beamsClient = new PusherPushNotifications.Client({
      instanceId: process.env.VUE_APP_BEAMS_INSTANCE_ID
    });
    this.beamsTokenProvider = new PusherPushNotifications.TokenProvider({
      url: process.env.VUE_APP_BACK_ROUTE + `api/pusher`,
      ...{
        headers: {
          Authorization: "Bearer " + token
        }
      }
    });
  }

  async start() {
    try {
      await this.beamsClient.start();
      await this.beamsClient.addDeviceInterest(process.env.VUE_APP_BEAMS_TOPIC);
      await this.beamsClient.setUserId(this.userId, this.beamsTokenProvider);
    } catch (err) {
      console.error("[START ERROR]", err.message);
    }
  }
  async stop() {
    try {
      await this.beamsClient.stop();
    } catch (err) {
      console.error("[STOP ERROR]", err.message);
    }
  }
}

export default BeamClient;
