import Vue from "vue";
import Vuex from "vuex";
import login from "./modules/login";
import account from "./modules/account";
import crmLoads from "./modules/crmLoads";
import crmPayables from "./modules/crmPayables";
import crmPayablesLine from "./modules/crmPayablesLine";
import emails from "./modules/emails";
import dbTags from "./modules/dbTags";
import dbFile from "./modules/dbFile";
import dbPayables from "./modules/dbPayables";
import dbSalesforceHistory from "./modules/dbSalesforceHistory";
import bucket from "./modules/bucket";
import dbLoads from "./modules/dbLoads";
import dbPageTypes from "./modules/dbPageTypes";
import users from "./modules/users";
import emailXTag from "./modules/dbEmailsXTags";
import ocr from "./modules/ocr";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    notification: 0
  },
  getters: {},
  mutations: {
    setNotification(state) {
      state.notification += 1;
    }
  },
  actions: {},
  modules: {
    account,
    login,
    crmLoads,
    crmPayables,
    crmPayablesLine,
    emails,
    dbTags,
    dbFile,
    dbPayables,
    dbSalesforceHistory,
    bucket,
    dbLoads,
    dbPageTypes,
    users,
    emailXTag,
    ocr
  }
});
