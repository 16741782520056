import axios from "axios";
import { errorMessage, tokenExpired, tokenExpiredSalesForce } from "@/api/errorMessages";

export default {
  namespaced: true,
  state: {
    dataPayables: null,
    payeeObject: null
  },
  mutations: {
    setDataPayables(state, newPayables) {
      state.dataPayable = newPayables;
    },
    setPayeeObject(state, newObject) {
      state.payeeObject = newObject;
    },
    setCleanPayeeObject(state) {
      state.payeeObject = null;
    }
  },
  getters: {
    showPayeeObject(state) {
      return state.payeeObject;
    }
  },
  actions: {
    async getDataPayables({ commit }, loadId) {
      try {
        let response = await axios.get(
          process.env.VUE_APP_BACK_ROUTE + `api/crm/payables/${loadId}`
        );
        if (response.status == 200) {
          commit("setDataPayables", response.data);
        }
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 403) {
          tokenExpiredSalesForce();
        } else if (err.response.status != 200) {
          errorMessage();
        }
      }
    },
    async updatePayables(_, data) {
      let params = {};
      params.payable_db_id = data.payable_db_id;
      try {
        let response = await axios.patch(
          process.env.VUE_APP_BACK_ROUTE +
          `api/crm/payables/${data.payable_crm_id}`,
          data.body,
          {
            params: params
          }
        );
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 403) {
          tokenExpiredSalesForce();
        } else if (err.response.status != 204) {
          errorMessage();
        }
        return err.response;
      }
    },
    async newPayment(_, data) {
      let params = {};
      try {
        let response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE + `api/crm/payables/posted`,
          data.body,
          {
            params: params
          }
        );
        if (response.status == 204) {
          swal({
            title: "Payment sent ",
            icon: "success"
          });
        }
        return response;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 404) {
          errorNotFoundMessage("Payable");
        }else if (err.response.status == 403) {
          tokenExpiredSalesForce();
        }else if (err.response.status != 204) {
          errorMessage();
        }
        return err.response;
      }
    }
  }
};
