<template>
  <div class="logout-button">
    <div class="text-right">
      <b-dropdown
        :variant="rol == 'Available' ? 'success' : 'danger'"
        size="lg"
        toggle-class="text-decoration-none"
        no-caret
        class="float"
        v-if="isDataCharged"
      >
        <template #button-content>
          <i class="fas fa-user my-float"></i>
        </template>
        <b-dropdown-form>
          <div class="dropdown-header">
            <b-avatar
              badge
              :badge-variant="rol == 'Available' ? 'success' : 'danger'"
              variant="dark"
            ></b-avatar>
            <span class="dropdown-header__username">
              {{ username }}
            </span>
          </div>
        </b-dropdown-form>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item @click="changeStatus">
          <b-icon
            icon="exclamation-circle-fill"
            animation="throb"
            :variant="rol == 'Available' ? 'danger' : 'success'"
          >
          </b-icon>
          {{ rol == "Available" ? "Busy" : "Available" }}
        </b-dropdown-item>
        <b-dropdown-item @click="logout"
          ><i class="fas fa-sign-out-alt sign-out-icon"></i>
          Logout</b-dropdown-item
        >
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import beamsClient from "@/services/beams.js";

export default {
  name: "LogoutButton",
  data() {
    return {
      username: "",
      userLoggedId: null,
      rol: {},
      isDataCharged: false
    };
  },
  async created() {
    /*  Obtain the user name that is logged into the system */
    let userInfo = await JSON.parse(
      this.$store.getters["login/showUserInfo"].sub
    );
    this.username = userInfo.username.toUpperCase();
    /* Get the user id that is logged into the system */
    this.userLoggedId = userInfo.id;
    /* Call to function get status  */
    await this.getStatus();
    this.isDataCharged = true;
  },
  methods: {
    /* Function to exit the application  */
    async logout() {
      this.$router.push({ name: "Login" });
      localStorage.removeItem("payments2.0-token");
      const BeamsClient = new beamsClient();
      BeamsClient.stop();
    },
    /* Function to get the status of the logged in user by sending the user's id. */
    async getStatus() {
      let result = await this.$store.dispatch(
        "users/getUserById",
        this.userLoggedId
      );
      this.rol = result.data.status.me;
    },
    /* Function to change the state inside the logoutButton  */
    async changeStatus() {
      if (this.rol == "Available") {
        await this.$store.dispatch("users/changeStatusUser", {
          new_status: "Busy"
        });
        this.getStatus();
      } else {
        await this.$store.dispatch("users/changeStatusUser", {
          new_status: "Available"
        });
        this.getStatus();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.float {
  cursor: pointer;
  position: fixed;
  width: 35px;
  height: 35px;
  bottom: 3%;
  left: 1.7%;
  z-index: 10000;
  color: #fff;
  border-radius: 50px;
  text-align: center;

  &__primary {
    background-color: $color-primary-button !important;
  }
  &__secondary {
    background-color: $color-secondary-button !important;
  }
}

.my-float {
  margin-top: 5px;
}

.dropdown-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &__username {
    margin-left: 10px;
    font-size: 16px;
  }
}

.sign-out-icon {
  color: #e53935;
}

@media (max-width: 1400px) {
  .float {
    left: 2.3%;
  }
}

::v-deep {
  & .btn-lg,
  .btn-group-lg > .btn {
    padding: 0;
    font-size: 1rem;
  }

  & .btn-link {
    color: $color-white;

    &:hover {
      color: $color-white;
    }
  }

  & .dropdown-menu {
    border-radius: 15px;
    @include box-shadow;
  }

  & .dropdown-item {
    padding: 10px 1.5rem;
    margin-right: 10px;
  }

  & .btn-lg,
  .btn-group-lg > .btn {
    border-radius: 50%;
  }
}
</style>
