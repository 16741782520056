import axios from "axios";
import swal from "sweetalert";
import {
  errorMessage,
  tokenExpired,
  errorNotFoundMessage
} from "@/api/errorMessages";

export default {
  namespaced: true,
  state: {
    sentToOcrFlag: 0
  },
  mutations: {
    setSentToOcrFlag(state) {
      state.sentToOcrFlag++;
    }
  },
  actions: {
    async sendToOcr({ commit }, emailId) {
      let params = {};
      params.email_id = emailId;
      try {
        let response = await axios.post(
          process.env.VUE_APP_BACK_ROUTE + `api/ocr`,
          null,
          {
            params: params
          }
        );
        if (response.status === 200) {
          swal({
            title: "Good job!",
            text: "Email sent to OCR processing",
            icon: "success"
          });
          commit("setSentToOcrFlag");
        }
        return response.data;
      } catch (err) {
        if (err.response.status == 401) {
          tokenExpired();
        } else if (err.response.status == 404) {
          errorNotFoundMessage("File");
        } else if (err.response.status == 409) {
          swal({
            title: "There is something wrong!",
            text: "There is no assigned Load",
            icon: "error",
            dangerMode: true
          });
        } else if (err.response.status != 200) {
          errorMessage();
        }
      }
    }
  }
};
