import swal from "sweetalert";
import router from "@/router";

export function errorMessage() {
  swal({
    title: "There is something wrong",
    text: "Please, try again.",
    icon: "error",
    dangerMode: true
  });
}

export function errorNotFoundMessage(event) {
  swal({
    title: "There is something wrong",
    text: `${event} not found!`,
    icon: "error",
    dangerMode: true
  });
}

export function tokenExpiredSalesForce() {
  swal({
    title: "The salesforce token has expired",
    text: "Please, login again",
    icon: "warning",
    buttons: true,
    dangerMode: true
  }).then(willDelete => {
    if (willDelete) {
      localStorage.removeItem("payments2.0-token");
    }
    router.push({
      name: "Login"
    });
  });
}

export function tokenExpired() {
  swal({
    title: "The token has expired",
    text: "Please, login again",
    icon: "warning",
    buttons: true,
    dangerMode: true
  }).then(willDelete => {
    if (willDelete) {
      localStorage.removeItem("payments2.0-token");
    }
    router.push({
      name: "Login"
    });
  });
}
